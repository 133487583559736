import InnerHeader from '../../components/innerheader/InnerHeader';
import './careersview.scss';

const CareersView = () => {
  return (
    <div>
      <InnerHeader>Careers</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='careers'>
            <h1 className='coming-soon'>Coming Soon....Keep visiting</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersView;
