import InnerHeader from '../../components/innerheader/InnerHeader';
import { FaChevronRight } from 'react-icons/fa';
import './cloudcomputingview.scss';

const CloudComputingView = () => {
  return (
    <div>
      <InnerHeader>Cloud Computing</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='cloudcomputing'>
            <div className='cloudcomputing-text'>
              Shifting to cloud computing has become very common these days.
              With Red Laser Technology, Inc. (RLT’s) cloud computing services,
              you can operate more efficiently as you can develop and support IT
              solutions. It is the on-demand access of business IT resources
              through the internet. Private clouds, public clouds, hybrid
              clouds, and multi-clouds are the four basic types of cloud
              computing. Most people opt for this service, because it allows
              them to minimize their costs, focus on process development and
              execute the business operations hassle-free. Rather than
              purchasing databases and servers, you can make use of
              technological services such as processing, applications,
              development tools, and data storage. Such resources and platforms
              managed by a cloud service provider ensures optimum speed and
              security.
            </div>

            <div className='cloudcomputing-benefits'>
              <h3 className='inner-para-heading'>Benefits</h3>
              <h4 className='cloudcomputing-benefits-heading'>
                1. Reduces Costs
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Avoids capital expenditures of investing in servers and managing
                expensive data centers
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Eliminates the cost of running your IT systems, fewer time
                delays, and lower variable expenses
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                2. Strengths Security
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Increases data security and tightens access control
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Guarantees access to confidential information
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Establishes customer loyalty and brand image
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Secures data from breaches and other crimes
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                3. Provides scope for Collaboration
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Encourages better collaboration and communication across groups
                to avoid confusion
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Promotes transparency
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Provides the ability to share data easily in an effective manner
              </div>
            </div>

            <div className='cloudcomputing-text'>
              Red Laser Technology takes pride in implementing the motto
              <span>"Empowerment through Innovation”</span>. The Corps of
              Engineers selected the Vicksburg District Antiterrorism Program as
              the “Best Standalone Facility Antiterrorism Program”. RLT’s staff,
              with its depth of experience, invested a lot of effort into the
              District's AT program and was recognized with an award! ISO
              27001:2013 was created to assist RLT in improving their
              information security and reducing the risk of business
              interruption. We implement ISO 27001:2013 as organizations of all
              kinds should engage in a proactive, protective, and mitigating
              strategy. RLT also implements ISO 9001:2015 to demonstrate quality
              assurance and to foster a customer-centric culture that is
              developed throughout the company. Apart from that, 9001:2000 is
              also incorporated with a focus on supplying goods and services to
              the customers and attaining customer satisfaction. Red Laser
              Technology uses these processes to safeguard the nation's data.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudComputingView;
