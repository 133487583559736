import { Link } from 'react-router-dom';
import './navigation.scss';

const Navigation = () => {
  const handleClick = () => {
    document.getElementById('navi-toggle').checked = false;
  };

  return (
    <div className='navigation'>
      <input
        type='checkbox'
        className='navigation__checkbox'
        id='navi-toggle'
      />

      <label htmlFor='navi-toggle' className='navigation__button'>
        <span className='navigation__icon'>&nbsp;</span>
      </label>

      <div className='navigation__background'>&nbsp;</div>

      <nav className='navigation__nav'>
        <ul className='navigation__list'>
          <li className='navigation__item'>
            <Link to='/' className='navigation__link' onClick={handleClick}>
              Home
            </Link>
          </li>
          <li className='navigation__item' onClick={handleClick}>
            <Link to='/about' className='navigation__link'>
              About Redlaser
            </Link>
          </li>
          <li className='navigation__item'>
            <Link
              to='/services'
              className='navigation__link'
              onClick={handleClick}
            >
              Services
            </Link>
          </li>
          <li className='navigation__item'>
            <Link
              to='/federal-contract-vehicles'
              className='navigation__link'
              onClick={handleClick}
            >
              Federal Contract Vehicles
            </Link>
          </li>
          <li className='navigation__item'>
            <Link
              to='/certifications'
              className='navigation__link'
              onClick={handleClick}
            >
              Certifications
            </Link>
          </li>
          <li className='navigation__item'>
            <Link
              to='/past-performances'
              className='navigation__link'
              onClick={handleClick}
            >
              Past Performance
            </Link>
          </li>
          <li className='navigation__item'>
            <Link
              to='/careers'
              className='navigation__link'
              onClick={handleClick}
            >
              Careers
            </Link>
          </li>
          <li className='navigation__item'>
            <Link
              to='/contact-us'
              className='navigation__link'
              onClick={handleClick}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
