import { Link } from 'react-router-dom';
import './pastperformances.scss';

const PastPerformances = () => {
  return (
    <section className='section-performances' id='section-performances'>
      <div className='u-center-text u-margin-bottom-big'>
        <h2 className='heading-secondary'>Our past performances</h2>
      </div>

      <div className='maingrid'>
        <div className='section-performances-content'>
          <div>
            <div className='card'>
              <div className='card__side card__side--front'>
                <div className='card__picture card__picture--1'>&nbsp;</div>
                <h4 className='card__heading'>
                  <span className='card__heading-span card__heading-span--1'>
                    Gulfport Biloxi Airport
                  </span>
                </h4>
                <div className='card__details'>
                  <ul>
                    <li>Upgraded Existing Wireless Network</li>
                    <li>
                      Supplied Comprehensive Network Performance Monitoring
                    </li>
                    <li>
                      Provided Penetration Testing and Vulnerability Assessments
                    </li>
                    <li>Delivered Robust Network Reports</li>
                    {/* <li>Difficulty: easy</li> */}
                  </ul>
                </div>
              </div>
              <div className='card__side card__side--back card__side--back-1'>
                <div className='card__cta'>
                  <div className='card__price-box'>
                    <p className='card__price-only'></p>
                    <p className='card__price-value'></p>
                  </div>
                  <Link to='/past-performances' className='btn btn-white'>
                    More Details
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className='card'>
              <div className='card__side card__side--front'>
                <div className='card__picture card__picture--2'>&nbsp;</div>
                {/* <h4 className='card__heading'>
                  <span className='card__heading-span card__heading-span--2'>
                    U.S. Army Corps of Engineers Vicksburg
                  </span>
                </h4> */}
                <div className='card__details'>
                  <ul>
                    <li>Award ID: W912EE20C0002</li>
                    <li>Maintained and Operated Electronic Security Systems</li>
                    <li>Provided Security Education Training and Awareness</li>
                    <li>Antiterrorism/Force Protection</li>
                  </ul>
                </div>
              </div>
              <div className='card__side card__side--back card__side--back-2'>
                <div className='card__cta'>
                  <div className='card__price-box'>
                    <p className='card__price-only'></p>
                    <p className='card__price-value'></p>
                  </div>
                  <Link to='/past-performances' className='btn btn-white'>
                    More Details
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className='card'>
              <div className='card__side card__side--front'>
                <div className='card__picture card__picture--3'>&nbsp;</div>
                {/* <h4 className='card__heading'>
                  <span className='card__heading-span card__heading-span--3'>
                    Department of Homeland Security
                  </span>
                </h4> */}
                <div className='card__details'>
                  <ul>
                    <li>Award ID: 70B06C20P00000617</li>
                    <li>Provided Electronic Transit Cargo Tracking System</li>
                    <li>Automated Smart Gates and Number Plate Recognition</li>
                    <li>
                      Optimized Security of Goods and Customers Experience
                    </li>
                  </ul>
                </div>
              </div>
              <div className='card__side card__side--back card__side--back-3'>
                <div className='card__cta'>
                  <div className='card__price-box'>
                    <p className='card__price-only'></p>
                    <p className='card__price-value'></p>
                  </div>
                  <Link to='/past-performances' className='btn btn-white'>
                    More Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='u-center-text u-margin-top-huge'>
        <Link to='/past-performances' className='btn btn-colored'>
          Discover some more performances
        </Link>
      </div>
    </section>
  );
};

export default PastPerformances;
