import { Link } from 'react-router-dom';

import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';

import { FaPhoneAlt } from 'react-icons/fa';
import './innerheader.scss';

const InnerHeader = (props) => {
  return (
    <div className='innerheader'>
      <div className='inner-topbar'>
        <div className='inner-logo'>
          <Link to='/'>
            <img src='img/redlaser-logo.png' alt='topbar logo' />
          </Link>
        </div>
        <div className='top-contact'>
          <div className='callno'>
            <span>
              <FaPhoneAlt />
            </span>
            601-624-9837
          </div>

          <div className='top-social-icons'>
            <a href='https://www.facebook.com' target='_blank'>
              <span>
                <FaFacebookF />
              </span>
            </a>
            {/* <span>
              <FaTwitter />
            </span>
            <span>
              <FaLinkedinIn />
            </span>
            <span>
              <FaInstagram />
            </span> */}
          </div>
        </div>
      </div>

      <div className='inner-banner'>
        <h1 className='inner-page-title'>{props.children}</h1>
      </div>
    </div>
  );
};

export default InnerHeader;
