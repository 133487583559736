import InnerHeader from '../../components/innerheader/InnerHeader';

import './stars3view.scss';

const Stars3View = () => {
  return (
    <div>
      <InnerHeader>
        <div className='stars3-page-title'>GSA 8(a) STARS III</div>{' '}
      </InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='stars3'>
            <div className='stars3-title'>
              <h3>Master Contract Number</h3>
            </div>
            <div className='stars3-data'>47QTCB22D0633</div>
            <div className='stars3-title'>
              <h3>Type</h3>
            </div>
            <div className='stars3-data'>MA-IDIQ</div>
            <div className='stars3-title'>
              <h3>Ceiling:</h3>
            </div>
            <div className='stars3-data'>$50 billion</div>
            <div className='stars3-title'>
              <h3>DUNS</h3>
            </div>
            <div className='stars3-data'>158976667</div>
            <div className='stars3-title'>
              <h3>NAICS Code</h3>
            </div>
            <div className='stars3-data'>
              541511 Custom Computer Programming Services 541513 Computer
              Facilities Management Services 541519 Other Computer Related
              Services 518210 Data Processing, Hosting, and Related Services
              541512 Computer Systems Design Services
            </div>
            <div className='stars3-title'>
              <h3>Unique Entity ID</h3>
            </div>
            <div className='stars3-data'>M8MM8MYPB2ZENR2</div>
            <div className='stars3-title'>
              <h3>CAGE/NCAGE</h3>
            </div>
            <div className='stars3-data'>3ZAU7</div>
            <div className='stars3-title'>
              <h3>Physical / Mailing Address</h3>
            </div>
            <div className='stars3-data address'>
              <ul>
                <li>1230 Raymond RD STE 1222</li>
                <li>Jackson, Mississippi</li>
                <li>39204-4583, United States</li>
              </ul>
            </div>
            <div className='stars3-title'>
              <h3>Program Manager</h3>
            </div>
            <div className='stars3-data'>
              <ul>
                <li>Adrian Jones</li>
                <li>adrianjones@redlaser.net</li>
                <li>6015738109</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stars3View;
