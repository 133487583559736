import './sectionabout.scss';

const SectionAbout = () => {
  return (
    <section className='maingrid'>
      <div className='section-about'>
        <div className='u-center-text u-margin-bottom-big'>
          <h2 className='heading-secondary'>
            Innovations to give you the edge
          </h2>
        </div>
        <div className='maingrid'>
          <div className='section-about-content'>
            <div className='section-about-text'>
              <h3 className='heading-tertiary u-margin-bottom-small'>
                How you will benefit from Redlaser
              </h3>
              <p className='paragraph'>
                Red Laser Technology, Inc. (RLT) is based in Jackson, MS. RLT is
                an advanced building automation development consulting firm with
                a unique focus and ability to leverage our algorithm-based
                technology, SOA based Cloud Computing methodology, wireless
                communications architectures, collective personal experiences,
                and technology company portfolio of relationships to lead
                clients support to their most difficult challenges.
              </p>

              <h3 className='heading-tertiary u-margin-bottom-small'>
                Technologies that give you the leap ahead
              </h3>
              <p className='paragraph'>
                As a company RedLaser Technology, Inc., and as individuals, we
                value integrity, truthfulness, ingenuousness, personal
                excellence, constructive self-criticism, continual
                self-improvement, and mutual respect.
              </p>

              <a href='/about' className='btn-text'>
                Learn more &rarr;
              </a>
            </div>
            <div className='section-about-images'>
              <div className='composition'>
                <img
                  srcSet='img/tech01.jpg 300w, img/tech01-large.jpg 1000w'
                  sizes='(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px'
                  alt='Pic 1'
                  className='composition__photo composition__photo--p1'
                  src='img/tech01-large.jpg'
                />

                <img
                  srcSet='img/tech02.jpg 300w, img/tech02-large.jpg 1000w'
                  sizes='(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px'
                  alt='Pic 2'
                  className='composition__photo composition__photo--p2'
                  src='img/tech02-large.jpg'
                />

                <img
                  srcSet='img/tech03.jpg 300w, img/tech03-large.jpg 1000w'
                  sizes='(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px'
                  alt='Pic 3'
                  className='composition__photo composition__photo--p3'
                  src='img/tech03-large.jpg'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionAbout;
