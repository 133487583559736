import { useRef } from 'react';
import emailjs from '@emailjs/browser';

import { FaMapMarkerAlt, FaPhoneAlt, FaFax } from 'react-icons/fa';

import InnerHeader from '../../components/innerheader/InnerHeader';

import './contactusview.scss';

const ContactUsView = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_jrq81ro',
        'redlaser_contact_form',
        form.current,
        'Ffij4hCYds3vn3-AG'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div>
      <InnerHeader>Contact Us</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='contactus'>
            <div className='contactus-form'>
              <h3 className='contactus-form-heading'>Send us a message</h3>
              <form
                ref={form}
                className='form'
                id='contactus'
                onSubmit={sendEmail}
              >
                <div>
                  <label htmlFor='sendername'>Sender Name *</label>
                  <input
                    type='text'
                    name='name'
                    id='sendername'
                    placeholder='Your Name'
                    required
                    // onChange={(e) => setSenderName(e.target.value)}
                  ></input>
                </div>

                <div>
                  <label htmlFor='senderemail'>Sender Email *</label>
                  <input
                    type='email'
                    name='email'
                    id='senderemail'
                    placeholder='Your Email'
                    required
                    // onChange={(e) => setSenderEmail(e.target.value)}
                  ></input>
                </div>

                <div>
                  <label htmlFor='sendersubject'>Subject *</label>
                  <input
                    type='text'
                    name='subject'
                    id='sendersubject'
                    placeholder='Subject'
                    required
                    // onChange={(e) => setSenderName(e.target.value)}
                  ></input>
                </div>

                <div>
                  <label htmlFor='sendermessage'>Your Message *</label>
                  <textarea
                    type='text'
                    name='message'
                    id='sendermessage'
                    placeholder='Your Message'
                    required
                    // onChange={(e) => setSenderMessage(e.target.value)}
                  ></textarea>
                </div>

                <div>
                  <label />
                  <button className='submit-button' type='submit'>
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className='contactus-details'>
              <h3 className='contactus-details-heading'>Get in touch</h3>
              <div className='contactus-details-icon'>
                <FaMapMarkerAlt />
              </div>
              <div className='contactus-details-address'>
                <h4>Red Laser Technology Inc.</h4>
                <h4>1230 Raymond Road Box 600</h4>
                <h4>JACKSON, MISSISSIPPI 39204</h4>
              </div>

              <div className='contactus-details-icon'>
                <FaPhoneAlt />
              </div>
              <div className='contactus-details-phone'>
                <h4>601-624-9837</h4>
              </div>

              <div className='contactus-details-icon'>
                <FaFax />
              </div>
              <div className='contactus-details-fax'>
                <h4>601-965-0359</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsView;
