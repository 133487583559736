import { useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import InnerHeader from '../../components/innerheader/InnerHeader';
import Img1 from '../../img/sba8a.png';
import Img2 from '../../img/sbahubzone.png';
import Img3 from '../../img/dbe.png';
import Img4 from '../../img/iso9001.jpg';
import Img5 from '../../img/mbe.jpg';
import Img6 from '../../img/mda.jpg';
import Img7 from '../../img/sbe.png';
import './certificationsview.scss';

const CertificationsView = () => {
  let certificates = [
    {
      id: 1,
      imgSrc: Img1,
      imageName: 'SBA 8(a) Certified',
      imageText: 'Certified July 2014 – July 2024',
      certificateLink:
        'https://www.sba.gov/federal-contracting/contracting-assistance-programs/8a-business-development-program',
    },
    // {
    //   id: 2,
    //   imgSrc: Img2,
    //   imageName: 'SBA HUBZone Certified',
    //   imageText: 'HUBZone Certified since 2015',
    //   certificateLink:
    //     'https://www.sba.gov/federal-contracting/contracting-assistance-programs/hubzone-program',
    // },
    {
      id: 3,
      imgSrc: Img3,
      imageName: 'DBE Certified in the following states',
      imageText: 'MS, TX, GA, FL, MD, AZ, LA',
      certificateLink:
        'https://www.transportation.gov/civil-rights/disadvantaged-business-enterprise',
    },
    {
      id: 4,
      imgSrc: Img4,
      imageName: 'ISO:9001:2015 Certified',
      imageText: 'Since 2019',
      certificateLink: 'https://www.iso.org/standard/62085.html',
    },
    {
      id: 5,
      imgSrc: Img5,
      imageName: 'Minority Business Enterprise Certified',
      imageText: 'Since 2003',
      certificateLink: 'https://www.jacksonms.gov/business-development/',
    },
    {
      id: 6,
      imgSrc: Img6,
      imageName: 'Minority / Small Business Certified',
      imageText: 'Since 2005',
      certificateLink: 'https://mississippi.org/minority-small-business/',
    },
    {
      id: 7,
      imgSrc: Img7,
      imageName: 'Small Business Enterprise Certified',
      imageText: 'Since 2019',
      certificateLink:
        'https://www.txdot.gov/business/disadvantaged-small-business-enterprise/sbe.html',
    },
  ];

  const [certificatemodal, setCertificateModal] = useState(false);
  const [modalimgSrc, setModalImgSrc] = useState('');
  const [modalmgName, setModalmgName] = useState('');
  const [modalCertificateLink, setModalCertificateLink] = useState('');
  const [modalImageText, setModalImageText] = useState('');

  const getCertificate = (imgSrc, imgName, imageText, certificateLink) => {
    setModalImgSrc(imgSrc);
    setModalmgName(imgName);
    setModalCertificateLink(certificateLink);
    setModalImageText(imageText);
    setCertificateModal(true);
  };
  return (
    <div>
      <InnerHeader>Certifications</InnerHeader>
      <div
        className={
          certificatemodal ? 'certificatemodal open' : 'certificatemodal'
        }
      >
        <div>
          <img src={modalimgSrc} alt='something' />
        </div>

        <a
          className='modallink'
          href={modalCertificateLink}
          target='_blank'
          without
          rel='noreferrer'
        >
          <h3 className='certificatemodal-text'>{modalmgName}</h3>
          <h4 className='modalimage-text'>{modalImageText}</h4>
        </a>

        <AiOutlineCloseCircle onClick={() => setCertificateModal(false)} />
      </div>

      <div className='inner-content'>
        <div className='maingrid'>
          <div className='certifications'>
            {certificates.map((item, index) => {
              return (
                <div
                  className='certificate'
                  key={index}
                  onClick={() =>
                    getCertificate(
                      item.imgSrc,
                      item.imageName,
                      item.imageText,
                      item.certificateLink
                    )
                  }
                >
                  <div className='certificate-image'>
                    <img src={item.imgSrc} alt='certificate name' />
                  </div>
                  <a
                    href={item.certificateLink}
                    target='_blank'
                    without
                    rel='noreferrer'
                  >
                    <div className='certificate-content'>
                      <div>{item.imageName}</div>
                      <div className='certificate-content-text'>
                        {item.imageText}
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificationsView;
