import InnerHeader from '../../components/innerheader/InnerHeader';
import './testimonialsview.scss';

import Img1 from '../../img/nat-8.jpg';
import Img2 from '../../img/nat-9.jpg';
import Img3 from '../../img/nat-8.jpg';
import Img4 from '../../img/nat-9.jpg';
import Img5 from '../../img/nat-8.jpg';

const TestimonialsView = () => {
  let testimonials = [
    {
      id: 1,
      clientName: 'Adrian Jones',
      clientCompany: 'Adrian Jones Inc.',
      clientImgSrc: Img1,
      clientTestimonial:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, non quam et laboriosam nobis sint necessitatibus eos culpa tempore similique veniam quod ab perferendis reiciendis.',
    },
    {
      id: 2,
      clientName: 'Avery Jones',
      clientCompany: 'Avery Jones Inc.',
      clientImgSrc: Img2,
      clientTestimonial:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, non quam et laboriosam nobis sint necessitatibus eos culpa tempore similique veniam quod ab perferendis reiciendis.',
    },
    {
      id: 3,
      clientName: 'Lisa Jones',
      clientCompany: 'Lisa Jones Inc.',
      clientImgSrc: Img3,
      clientTestimonial:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, non quam et laboriosam nobis sint necessitatibus eos culpa tempore similique veniam quod ab perferendis reiciendis.',
    },
    {
      id: 4,
      clientName: 'Ainsley Jones',
      clientCompany: 'Ainsley Jones Inc.',
      clientImgSrc: Img4,
      clientTestimonial:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, non quam et laboriosam nobis sint necessitatibus eos culpa tempore similique veniam quod ab perferendis reiciendis.',
    },
    {
      id: 5,
      clientName: 'Mary Jones',
      clientCompany: 'Mary Jones Inc.',
      clientImgSrc: Img5,
      clientTestimonial:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, non quam et laboriosam nobis sint necessitatibus eos culpa tempore similique veniam quod ab perferendis reiciendis.',
    },
  ];

  return (
    <div>
      <InnerHeader>Our Clients Say...</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='testimonials'>
            {testimonials.map((item, index) => {
              return (
                <div className='testimonial' key={index}>
                  <div className='client-image'>
                    <img src={item.clientImgSrc} alt='something' />
                  </div>
                  <div className='client-details'>
                    <div className='client-identity'>
                      {item.clientName}, {item.clientCompany}
                    </div>
                    <div className='client-testimonial'>
                      {item.clientTestimonial}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsView;
