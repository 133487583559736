import InnerHeader from '../../components/innerheader/InnerHeader';
import { FaChevronRight } from 'react-icons/fa';
import './electricalcontractorsview.scss';

const ElectricalContractorsView = () => {
  return (
    <div>
      <InnerHeader>Electrical Contractors</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='cloudcomputing'>
            <div className='cloudcomputing-text'>
              Electrical contractors are business persons or firms who do
              specialized construction work. They contribute a lot to our daily
              lives and there might be very few projects that would not need
              their assistance.
              <div className='cloudcomputing-text'>
                From installation to maintenance of devices, they make sure that
                these systems work safely and in an undisturbed manner.
                Moreover, contractors are not the same as electricians. An
                electrical contractor is a business person who employs
                electricians. Managing relationships, allocating resources,
                forming budgets, and creating benchmarks are some of the
                prominent functions of an electrical contractor.
              </div>
            </div>
            <div className='cloudcomputing-benefits'>
              <h3 className='inner-para-heading'>Benefits</h3>
              <h4 className='cloudcomputing-benefits-heading'>
                1. Highly cost-effective
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Handle the project professionally, give better results, reduce
                life risks, and save costs.
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                High-quality services to Red Laser's customers. With experienced
                electrical contractors
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                2. Benefit of Expertise
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Have years of experience in handling all kinds of electrical
                systems and are skilled to solve any issue that may arise in
                homes or offices
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Boosts client confidence, and make you survive in the market for
                a longer time
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Since RLT has already served hundreds of customers, they are
                confident to resolve even the most complex problems.
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                3. Safety for the long-term
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Convenient and provide great results
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Since they are licensed, electrical contractors perform their
                jobs without a scope of mistake and thus ensure long-term
                safety.
              </div>
            </div>
            <div className='cloudcomputing-text'>
              Red Laser Technology stays true to its philosophy,
              <span>"Empowerment through Innovation”</span>. The Vicksburg
              District Antiterrorism Program was selected as the "Best
              Standalone Facility Antiterrorism Program" by the Corps of
              Engineers. The company's staff put a lot of effort into the
              District's AT program and was awarded for it.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectricalContractorsView;
