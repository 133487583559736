import { Link } from 'react-router-dom';

import './footer.scss';
import { FaChevronRight } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaTwitterSquare } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaInstagramSquare } from 'react-icons/fa';
import { FaRegCopyright } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='maingrid'>
      <footer className='footer'>
        <div className='footer-logo'>
          <Link to='/'>
            <img
              src='img/redlaser-logo.png'
              alt='Logo'
              className='header-logo'
            />
          </Link>
        </div>
        <div className='footer-site-pages'>
          <div className='footer-page'>
            <Link to='/'>
              <span>
                <FaChevronRight />
              </span>
              Home
            </Link>
          </div>
          <div className='footer-page'>
            <Link to='/about'>
              <span>
                <FaChevronRight />
              </span>
              About Redlaser
            </Link>
          </div>
          <div className='footer-page'>
            <Link to='/services'>
              <span>
                <FaChevronRight />
              </span>
              Services
            </Link>
          </div>
          <div className='footer-page'>
            <Link to='/federal-contract-vehicles'>
              <span>
                <FaChevronRight />
              </span>
              Federal Contract Vehicles
            </Link>
          </div>
          <div className='footer-page'>
            <Link to='/certifications'>
              <span>
                <FaChevronRight />
              </span>
              Certifications
            </Link>
          </div>
          <div className='footer-page'>
            <Link to='/past-performances'>
              <span>
                <FaChevronRight />
              </span>
              Past Performance
            </Link>
          </div>
          <div className='footer-page'>
            <Link to='/careers'>
              <span>
                <FaChevronRight />
              </span>
              Careers
            </Link>
          </div>
          <div className='footer-page'>
            <Link to='/contact-us'>
              <span>
                <FaChevronRight />
              </span>
              Contact Us
            </Link>
          </div>
        </div>
        <div className='footer-contact'>
          <span className='company-name'>Red Laser Technology Inc</span>
          <span>1230 Raymond Road Box 600</span>
          <span>Jackson Mississippi 39204</span>

          <span className='phoneno'>Call us: 601-624-9837</span>
          <span className='faxno'>Fax us: 601-965-0359</span>
        </div>
        {/* <div className='footer-social'>
          <span>
            <FaFacebookSquare />
          </span>
          <span>
            <FaTwitterSquare />
          </span>
          <span>
            <FaLinkedin />
          </span>
          <span>
            <FaInstagramSquare />
          </span>
        </div> */}
      </footer>
      <div className='copyright'>
        <span>
          <FaRegCopyright />
        </span>
        Copyright Red Laser TechnologyInc 2022-2025
      </div>
    </div>
  );
};

export default Footer;
