import { Link } from 'react-router-dom';
import './headerhome.scss';

const HeaderHome = () => {
  return (
    <header className='header'>
      <div className='logo-box'>
        <Link to='/'>
          <img src='img/redlaser-logo.png' alt='Logo' className='header-logo' />
        </Link>
      </div>

      <div className='header-text-box'>
        <h1 className='heading-primary'>
          <span className='heading-primary-main'>Redlaser</span>
          <span className='heading-primary-sub'>
            is where technology happens
          </span>
        </h1>

        <Link to='/verticals' className='btn btn-white btn-animated'>
          Our Leading Edge Technologies
        </Link>
      </div>
    </header>
  );
};

export default HeaderHome;
