import { Link } from 'react-router-dom';

import { TbCloudComputing } from 'react-icons/tb';
import { FaConnectdevelop } from 'react-icons/fa';
import { FaCodeBranch } from 'react-icons/fa';
import { FaUserLock } from 'react-icons/fa';
import { FaProjectDiagram } from 'react-icons/fa';
import { FaJoint } from 'react-icons/fa';
import { FaRegBuilding } from 'react-icons/fa';
import { SiDassaultsystemes } from 'react-icons/si';
import { MdElectricalServices } from 'react-icons/md';

import InnerHeader from '../../components/innerheader/InnerHeader';
import './servicesview.scss';

const ServicesView = () => {
  return (
    <div>
      <InnerHeader>Services</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='verticals'>
            <h2 className='inner-main-heading'>Core Competencies</h2>
            <p className='verticals-intro'>
              Red Laser is a leading technology development and consulting firm.
              It focuses on offering a wide range of services, such as Cloud
              Computing, Logistics, Project Management, Cyber Security, and
              Systems and Infrastructure. RLT also uses SOA-based Cloud
              Computing approaches, Algorithm-based technologies, Wireless
              communications structures &amp; its ties with Technology
              companies.
            </p>
            <div className='verticals-info'>
              <div className='verticals-single-info'>
                <Link to='/cloud-computing'>
                  <h3 className='verticals-single-heading'>Cloud Computing</h3>
                  <div className='verticals-single-image'>
                    <TbCloudComputing />
                  </div>
                  <div className='verticals-single-summary'>
                    Cloud computing refers to the process of storing &amp; using
                    software and data on distant servers connected to the
                    internet. RLT&#39;s cloud services are beneficial to both
                    individuals and businesses.
                  </div>
                </Link>
              </div>
              <div className='verticals-single-info'>
                <Link to='/cyber-security'>
                  <h3 className='verticals-single-heading'>Cyber Security</h3>
                  <div className='verticals-single-image'>
                    <FaUserLock />
                  </div>
                  <div className='verticals-single-summary'>
                    The technique of securing systems from online threats is
                    known as Cyber security. Although Cyber security is a
                    daunting task, RLT is dedicated to shielding its consumers
                    from digital frauds.
                  </div>
                </Link>
              </div>
              {/* <div className='verticals-single-info'>
                <Link to='#/'>
                  <h3 className='verticals-single-heading'>
                    MObile Application Development
                  </h3>
                  <div className='verticals-single-image'>
                    <FaCodeBranch />
                  </div>
                  <div className='verticals-single-summary'>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Modi est saepe dolores ad impedit beatae neque repellendus
                    vel, sapiente a suscipit dolorum. Excepturi laudantium sit
                    minus nostrum eius expedita quos.
                  </div>
                </Link>
              </div> */}
              <div className='verticals-single-info'>
                <Link to='/software-development'>
                  <h3 className='verticals-single-heading'>
                    Software Development
                  </h3>
                  <div className='verticals-single-image'>
                    <FaConnectdevelop />
                  </div>
                  <div className='verticals-single-summary'>
                    Software development is crucial for businesses since it
                    enables them to increase their competitiveness &amp;
                    strengthen security. It means generating a piece of software
                    to carry out a specific purpose.
                  </div>
                </Link>
              </div>
              <div className='verticals-single-info'>
                <Link to='/project-management'>
                  <h3 className='verticals-single-heading'>
                    Project Management
                  </h3>
                  <div className='verticals-single-image'>
                    <FaProjectDiagram />
                  </div>
                  <div className='verticals-single-summary'>
                    Project managers wanting to thrive in their jobs can make
                    use of RLT’s services. It helps with everything from
                    clarifying everyone&#39;s role to improving team cooperation
                    while setting clear plans.
                  </div>
                </Link>
              </div>
              <div className='verticals-single-info'>
                <Link to='/electrical-contractors'>
                  <h3 className='verticals-single-heading'>
                    Electrical Contractors
                  </h3>
                  <div className='verticals-single-image'>
                    <MdElectricalServices />
                  </div>
                  <div className='verticals-single-summary'>
                    These are companies or individuals engaged in the business
                    of designing, installing &amp; maintaining electrical
                    systems. Making sure the systems are reliable as well as
                    environment-friendly is their primary duty.
                  </div>
                </Link>
              </div>
              <div className='verticals-single-info'>
                <Link to='/general-construction'>
                  <h3 className='verticals-single-heading'>
                    General Construction
                  </h3>
                  <div className='verticals-single-image'>
                    <FaRegBuilding />
                  </div>
                  <div className='verticals-single-summary'>
                    General contractors manage the execution of a project.
                    Employing a general contractor has many advantages, one of
                    which is that they have a wide network of subcontractors
                    functioning for them.
                  </div>
                </Link>
              </div>
              <div className='verticals-single-info'>
                <Link to='/systems-and-infrastructure'>
                  <h3 className='verticals-single-heading'>
                    Systems And Infrastructure
                  </h3>
                  <div className='verticals-single-image'>
                    <SiDassaultsystemes />
                  </div>
                  <div className='verticals-single-summary'>
                    IT infrastructure can be set up on-site at a company&#39;s
                    premises. In addition to lowering costs, this reliable
                    solution from Red Laser also assures efficient data
                    management and boosts uptime.
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesView;
