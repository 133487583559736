import InnerHeader from '../../components/innerheader/InnerHeader';
import { FaChevronRight } from 'react-icons/fa';
import './cybersecurityview.scss';

const CyberSecurityView = () => {
  return (
    <div>
      <InnerHeader>Cyber Security</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='cloudcomputing'>
            <div className='cloudcomputing-text'>
              Cybersecurity is extremely important, especially in businesses. As
              the name suggests, it is the practice of securing and protecting
              systems, programs, and networks from cyber-attacks. Attackers are
              becoming creative and they use different devices to destroy
              sensitive data or to extort money from people.
              <div className='cloudcomputing-text'>
                Red Laser Technology, with its remarkable service, takes on big
                challenges and is committed to protecting its users from digital
                crimes. Cybersecurity is necessary yet challenging. When
                establishing cybersecurity procedures, a layered security model
                can be used. This model starts with the internet and is finished
                with your employees.
              </div>
            </div>
            <div className='cloudcomputing-benefits'>
              <h3 className='inner-para-heading'>Benefits</h3>
              <h4 className='cloudcomputing-benefits-heading'>
                1. Better Productivity
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Prevent and remove viruses from computers and systems, allowing
                better usage of time.
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                With solid IT support solutions, more operations can be executed
                at the same time which results in enhanced productivity.
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                2. Build Customer Trust
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Help in ensuring that your business is protected from malicious
                attacks
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Boosts client confidence, and make you survive in the market for
                a longer time
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                RLT values integrity and truthfulness as well as holds itself
                accountable to its customers.
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                3. Allow staff to work safely
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Protect the confidential data of your employees
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Help them concentrate on their responsibilities, and lead to
                better functioning
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Develop a healthy culture within the organization.
              </div>
            </div>
            <div className='cloudcomputing-text'>
              Red Laser Technology has a motto,
              <span>"Empowerment through Innovation”</span>. The Vicksburg
              District Antiterrorism Program was selected as the "Best
              Standalone Facility Antiterrorism Program" by the Corps of
              Engineers. The company’s staff contributed significantly to the
              District's AT program and was acknowledged with an award.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CyberSecurityView;
