import { Link } from 'react-router-dom';

import InnerHeader from '../../components/innerheader/InnerHeader';
import './federalcontractvehiclesview.scss';

const FederalContractVehiclesView = () => {
  return (
    <div>
      <InnerHeader>
        <div className='fcv-title'>Federal Contract Vehicles</div>
      </InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='fcv'>
            <div className='left-col'>
              <Link to='/stars3'>
                <button className='fcv-button'>GSA 8(a) STARS III</button>
              </Link>
            </div>
            <div className='right-col'>
              <Link to='/gsa-schedule-70'>
                <button className='fcv-button'>GSA MAS</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FederalContractVehiclesView;
