import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ScrollToTop from './helpers/ScrollToTop';

import './App.scss';
import Footer from './frontend/components/footer/Footer';
import Navigation from './frontend/components/navigation/Navigation';
import AboutView from './frontend/views/about/AboutView';
import CareersView from './frontend/views/careers/CareersView';
import CertificationsView from './frontend/views/certifications/CertificationsView';
import CloudComputingView from './frontend/views/cloudcomputing/CloudComputingView';
import ContactUsView from './frontend/views/contactus/ContactUsView';
import CyberSecurityView from './frontend/views/cybersecurity/CyberSecurityView';
import HomeView from './frontend/views/home/HomeView';
import VerticalsView from './frontend/views/services/ServicesView';
import TestimonialsView from './frontend/views/testimonials/TestimonialsView';
import PastPerformancesView from './frontend/views/pastperformances/PastPerformancesView';
import SoftwareDevelopmentView from './frontend/views/softwaredevelopment/SoftwareDevelopmentView';
import ProjectManagementView from './frontend/views/projectmanagement/ProjectManagementView';
import ElectricalContractorsView from './frontend/views/electricalcontractors/ElectricalContractorsView';
import GeneralConstructionView from './frontend/views/generalconstruction/GeneralConstructionView';
import SystemsAndInfrastructureView from './frontend/views/systemsinfrastructure/SystemsAndInfrastructureView';
import FederalContractVehiclesView from './frontend/views/federalcontractvehicles/FederalContractVehiclesView';
import Stars3View from './frontend/views/stars3/Stars3View';
import GSASchedule70View from './frontend/views/gsaschedule70/GSASchedule70View';
import ServicesView from './frontend/views/services/ServicesView';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Navigation />
      <Routes>
        <Route path='/' element={<HomeView />} />
        <Route path='/about' element={<AboutView />} />
        <Route path='/services' element={<ServicesView />} />
        <Route
          path='/federal-contract-vehicles'
          element={<FederalContractVehiclesView />}
        />
        <Route path='/stars3' element={<Stars3View />} />
        <Route path='/gsa-schedule-70' element={<GSASchedule70View />} />
        <Route path='/certifications' element={<CertificationsView />} />
        <Route path='/careers' element={<CareersView />} />
        <Route path='/contact-us' element={<ContactUsView />} />
        <Route path='/cloud-computing' element={<CloudComputingView />} />
        <Route path='/cyber-security' element={<CyberSecurityView />} />
        <Route
          path='/software-development'
          element={<SoftwareDevelopmentView />}
        />
        <Route path='/project-management' element={<ProjectManagementView />} />
        <Route
          path='/electrical-contractors'
          element={<ElectricalContractorsView />}
        />
        <Route
          path='/general-construction'
          element={<GeneralConstructionView />}
        />
        <Route
          path='/systems-and-infrastructure'
          element={<SystemsAndInfrastructureView />}
        />
        <Route path='/testimonials' element={<TestimonialsView />} />
        <Route path='/past-performances' element={<PastPerformancesView />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
