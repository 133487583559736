import InnerHeader from '../../components/innerheader/InnerHeader';

import './gsaschedule70view.scss';

const GSASchedule70View = () => {
  return (
    <div>
      <InnerHeader>
        <div className='stars3-page-title'>GSA Schedule 70</div>{' '}
      </InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='stars3'>
            <div className='stars3-title'>
              <h3>Master Contract Number</h3>
            </div>
            <div className='stars3-data'>47QTCA20D00FD</div>
            <div className='stars3-title'>
              <h3>DUNS</h3>
            </div>
            <div className='stars3-data'>158976667</div>
            <div className='stars3-title'>
              <h3>User Agencies:</h3>
            </div>
            <div className='stars3-data'>All Federal and State</div>
            <div className='stars3-title'>
              <h3>Unique Entity ID</h3>
            </div>
            <div className='stars3-data'>M8MM8MYPB2ZENR2</div>
            <div className='stars3-title'>
              <h3>SINS</h3>
            </div>
            <div className='stars3-data gsa70'>
              54151S: Professional IT Services 33411 &amp; 33411REF: IT Hardware
              511210 &amp; 54151: Software and Maintenance 54151HACS:
              Cybersecurity
            </div>
            <div className='stars3-title'>
              <h3>Physical / Mailing Address</h3>
            </div>
            <div className='stars3-data address'>
              <ul>
                <li>1230 Raymond RD STE 1222</li>
                <li>Jackson, Mississippi</li>
                <li>39204-4583, United States</li>
              </ul>
            </div>
            <div className='stars3-title'>
              <h3>Program Manager</h3>
            </div>
            <div className='stars3-data'>
              <ul>
                <li>Adrian Jones</li>
                <li>adrianjones@redlaser.net</li>
                <li>6015738109</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GSASchedule70View;
