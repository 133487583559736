import { BiChevronRight } from 'react-icons/bi';

import InnerHeader from '../../components/innerheader/InnerHeader';
import './aboutview.scss';

const AboutView = () => {
  return (
    <div>
      <InnerHeader>About</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='about-content-text'>
            <p className='innerpara'>
              Red Laser Technology, Inc. (RLT) is based in Jackson, MS. RLT is
              an advanced building automation development consulting firm with a
              unique focus and ability to leverage our algorithm-based
              technology, SOA based Cloud Computing methodology, wireless
              communications architectures, collective personal experiences, and
              technology company portfolio of relationships to lead clients
              support to their most difficult challenges. Our systems approach
              is designed to respond to “out of the box” challenges, providing
              them with effective best practices, repeatable, sustainable,
              survivable, and secure (RS-3) solutions. Our Business Analytics
              and Intelligence (BAI) Solutions marries our Senior Subject Matter
              Experts (SME) experience in technology development and large-scale
              deployments, management, “policy to application” (P2A) work flow
              and rule-based adjudication, financial and engineering
              competencies with the company’s portfolio of advanced software,
              hardware applications, CMMI documentation, and performance-based
              metrics to provide clients with strategic and focused results.
            </p>
            <h3 className='inner-para-heading'>Advantages</h3>
            <p className='innerpara'>
              As a company RedLaser Technology, Inc., and as individuals, we
              value integrity, truthfulness, ingenuousness, personal excellence,
              constructive self-criticism, continual self-improvement, and
              mutual respect. We are committed to our customers and partners and
              have a passion for technology. We take on big challenges and pride
              ourselves in seeing that the tasks are completed. We hold
              ourselves accountable to our customers, partners, and employees by
              honoring our commitments, providing results, and striving for the
              highest quality.
            </p>
          </div>
          <div className='company-info'>
            <div className='company-info-section'>
              <div className='company-info-heading'>Company Data</div>
              <div className='company-info-data'>
                <p className='company-info-data-heading'>Cage Code</p>
                <p className='company-info-data-data'>3ZAU7</p>
                <p className='company-info-data-heading'>DUNS #</p>
                <p className='company-info-data-data'>158976667</p>
                <p className='company-info-data-heading'>
                  Certificate of Responsibility
                </p>
                <p className='company-info-data-data'>No. 23836-MC</p>
                <p className='company-info-data-heading'>
                  Security Clearance Level
                </p>
                <p className='company-info-data-data'>Secret</p>
              </div>
            </div>

            <div className='company-info-section'>
              <div className='company-info-heading'>Company Identifiers</div>
              <div className='company-info-section-data'>
                <BiChevronRight /> Minority Owned Small Business
              </div>
            </div>

            <div className='company-info-section'>
              <div className='company-info-heading'>
                SBA Certified 8(a) Program Participant
              </div>
              <div className='company-info-section-data'>
                <div>
                  <BiChevronRight /> SBA 8(a)Entrance Date: 06/12/2014
                </div>
                <div>
                  <BiChevronRight /> SBA 8(a) Exit Date: 06/12/2024
                </div>
              </div>
            </div>

            {/* <div className='company-info-section'>
              <div className='company-info-heading'>
                HUBZone Cer tified Program Participant
              </div>
              <div className='company-info-section-data'>
                <BiChevronRight /> HUBZone Certification Date: 01/27/2015
              </div>
            </div> */}

            <div className='company-info-section'>
              <div className='company-info-heading'>
                Independent Contractor for:
              </div>
              <div className='company-info-section-data'>
                <div>
                  <BiChevronRight /> Tech Data Government Solutions LLC
                </div>
                <div>
                  <BiChevronRight /> GSA Authorized Reseller
                </div>
                <div>
                  <BiChevronRight /> GSA GS-35-F-0349S
                </div>
                <div>
                  <BiChevronRight /> GSA Mutiple Awards Schedule:
                </div>
              </div>
            </div>

            <div className='company-info-section'>
              <div className='company-info-heading'>Company Snapshot</div>
              <div className='company-snapshot'>
                <div className='company-snapshot-heading'>GOV. POC</div>
                <div className='company-snapshot-info'>Adrian Jones</div>
                <div className='company-snapshot-heading'>Phone</div>
                <div className='company-snapshot-info'>(601) 573-8109</div>
                <div className='company-snapshot-heading'>Email</div>
                <div className='company-snapshot-info'>
                  adrianjones@redlaser.net
                </div>
                <div className='company-snapshot-heading'>Address</div>
                <div className='company-snapshot-info'>
                  1230 Raymond Road Suite 1222 Jackson, MS, 39204
                </div>
                <div className='company-snapshot-heading'>Work Area</div>
                <div className='company-snapshot-info'>Nationwide</div>
              </div>
            </div>

            <div className='company-info-section'>
              <div className='company-info-heading'>NAICS & PSC CODES</div>
              <div className='company-snapshot'>
                <div className='company-snapshot-heading'>541511</div>
                <div className='company-snapshot-info'>
                  Custom Computer Programming
                </div>
                <div className='company-snapshot-heading'>611420</div>
                <div className='company-snapshot-info'>Computer Training</div>
                <div className='company-snapshot-heading'>541512</div>
                <div className='company-snapshot-info'>
                  Computer Systems Design Services
                </div>
                <div className='company-snapshot-heading'>361710</div>
                <div className='company-snapshot-info'>
                  Educational Support Services
                </div>
                <div className='company-snapshot-heading'>423430</div>
                <div className='company-snapshot-info'>
                  Computer & Computer Peripheral Equipment & Software Merchant
                </div>
                <div className='company-snapshot-heading'>511210</div>
                <div className='company-snapshot-info'>Software Publishers</div>
                <div className='company-snapshot-heading'>517410</div>
                <div className='company-snapshot-info'>
                  Satellite Telecommunications
                </div>
                <div className='company-snapshot-heading'>334614</div>
                <div className='company-snapshot-info'>
                  Software & Other Prerecorded Compact Disc, Tape & Record
                  Reproducing
                </div>
                <div className='company-snapshot-heading'>561611</div>
                <div className='company-snapshot-info'>
                  Investigation and Personal Background Check Services
                </div>
                <div className='company-snapshot-heading'>561621</div>
                <div className='company-snapshot-info'>
                  Security Systems Services
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutView;
