import InnerHeader from '../../components/innerheader/InnerHeader';
import { FaChevronRight } from 'react-icons/fa';
import './systemsandinfrastructureview.scss';

const SystemsAndInfrastructureView = () => {
  return (
    <div>
      <InnerHeader>Systems And Infrastructure</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='cloudcomputing'>
            <div className='cloudcomputing-text'>
              Information technology infrastructure can be defined as the
              components that support the management of enterprise IT
              environments. It is formed of physical and virtual resources
              required to maintain the data. IT infrastructure system can offer
              you a brilliant opportunity to attract long-term clients by
              helping you deliver top-notch services to them. Not only does this
              robust system by RLT minimizes your expenses, but it also ensures
              to manage data effectively and eliminates inconsistencies within
              the organization. Components used to deliver IT solutions include
              software, hardware, networking components, and data storage.
            </div>
            <div className='cloudcomputing-benefits'>
              <h3 className='inner-para-heading'>Benefits</h3>
              <h4 className='cloudcomputing-benefits-heading'>
                1. Enhance Productivity
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Result in better performance, increase the morale of employees
                and offer a positive user experience
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Connect departments with streamlining processes and help
                stakeholders to get a clear picture of the task.
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                2. Advanced Security
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Ensure that sensitive data is protected and stored safe from all
                kinds of breaches
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Acquire additional levels of security, namely identity
                management, data security, multiple prebuilt authentication
                options, and access control on mobile devices.
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                3. Stronger Networks
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Reduce complexity, improve business operations and get involved
                in healthy completion
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Help in developing different business strategies.
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                4. Increased Global Presence
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Make sure to keep your data secured and highly accessible by the
                stakeholder at any point of time.
              </div>
            </div>
            <div className='cloudcomputing-text'>
              Red Laser Technology has a motto,
              <span>"Empowerment through Innovation”</span>. The Vicksburg
              District Antiterrorism Program was selected as the "Best
              Standalone Facility Antiterrorism Program" by the Corps of
              Engineers. The company’s staff contributed significantly to the
              District's AT program and was acknowledged with an award.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemsAndInfrastructureView;
