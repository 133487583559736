import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './homelogoslider.scss';

const HomeLogoSlider = ({ logoimages }) => {
  const sliderSettings = {
    infinite: true,
    dots: false,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlider: 0,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className='clientslider'>
      <h1 className='our-client-logos'>Clients</h1>
      <div className='homelogoslider'>
        <div className='logoslider'>
          <Slider {...sliderSettings}>
            {logoimages.map((logoimage) => (
              <div key={logoimage.id}>
                <img src={logoimage.src} alt={logoimage.alt} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HomeLogoSlider;
