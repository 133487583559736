import InnerHeader from '../../components/innerheader/InnerHeader';
import { FaChevronRight } from 'react-icons/fa';
import './projectmanagementview.scss';

const ProjectManagementView = () => {
  return (
    <div>
      <InnerHeader>Project Management</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='cloudcomputing'>
            <div className='cloudcomputing-text'>
              Project management is the process of utilizing all the tools,
              resources, and techniques to deliver the output within a specific
              time period. It is concerned with planning, tracking, and managing
              the different resources. Additionally, project team members
              include a project leader, business analysts, sponsors, trainers,
              and customers.
              <div className='cloudcomputing-text'>
                Red Laser’s project management services are a definite ticket
                for every project manager to achieve excellence in his work.
                Tasks and activities included in this process enable the team to
                work efficiently and carry out the project with success. With an
                effective project management tool, you can keep all the
                information about your project in one place and deliver work
                consistently.
              </div>
            </div>
            <div className='cloudcomputing-benefits'>
              <h3 className='inner-para-heading'>Benefits</h3>
              <h4 className='cloudcomputing-benefits-heading'>
                1. Increased Efficiency
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Aim at tremendously improving efficiency, saving time, and
                lowering risk
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                By offering a clear blueprint, this service by RLT helps the
                project manager in working even more productively.
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                2. Better Customer satisfaction
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Keep the customers happy as the motto is to complete the project
                on deadline and within budget
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Expansion of business, increased revenue, and brand value.
              </div>

              <h4 className='cloudcomputing-benefits-heading'>
                3. Improve Flexibility
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                With a clear roadmap, processes and procedures can be created
                beforehand
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Provide your enterprise the flexibility to implement different
                strategies and promote growth.
              </div>
            </div>
            <div className='cloudcomputing-text'>
              Red Laser Technology believes strongly in the slogan,
              <span>"Empowerment through Innovation”</span>. The Vicksburg
              District Antiterrorism Program was selected as the "Best
              Standalone Facility Antiterrorism Program" by the Corps of
              Engineers. The company’s staff put a lot of effort into the
              District's AT program and won an award.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectManagementView;
