import { Link } from 'react-router-dom';
import './testimonials.scss';

const Testimonials = () => {
  return (
    <section className='section-stories'>
      <div className='bg-video'>
        <video className='bg-video__content' autoPlay muted loop>
          <source src='img/video01.mp4' type='video/mp4' />
          <source src='img/video01.webm' type='video/webm' />
          Your browser is not supported!
        </video>
      </div>

      <div className='u-center-text u-margin-bottom-big'>
        <h2 className='heading-secondary'>Our Satisfied Customers..</h2>
      </div>

      <div className='maingrid'>
        <div className='story-content top-story'>
          <div className='story'>
            <figure className='story__shape'>
              <img
                src='img/logos/logo7.png'
                // src='img/nat-8.jpg'
                alt='Person on a tour'
                className='story__img'
              />
              <figcaption className='story__caption'>US Customs</figcaption>
            </figure>
            <div className='story__text'>
              <h3 className='heading-tertiary u-margin-bottom-small'>
                I would also like to state that Red laser Inc. was very
                responsive and supportive in completing this project.
              </h3>
              <p>
                I am pleased to inform you that the perimeter fence system is up
                and running. The techs were able to complete the installation
                and the system test was successful.This e-mail is my
                notification that the project is complete and has tested
                satisfactory. I would also like to state that Red laser Inc. was
                very responsive and supportive in completing this project. Due
                to the complexity of the integration of the upgraded security
                fence system to the existing security systems Mr. Adrian and his
                staff was always supporting US Customs to insure that the final
                product would be completed and functional as we expected. I want
                to thank Red Laser and all for your support on this project.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='maingrid'>
        <div div className='story-content'>
          <div className='story'>
            <figure className='story__shape'>
              <img
                src='img/logos/logo8.png'
                // src='img/nat-9.jpg'
                alt='Person on a tour'
                className='story__img'
              />
              <figcaption className='story__caption'>MPTAP</figcaption>
            </figure>
            <div className='story__text'>
              <h3 className='heading-tertiary u-margin-bottom-small'>
                Adrian is a true testament to hard work and determination
              </h3>
              <p>
                Join me in CONGRATULATING Adrian Jones with RedLaser Technology
                on obtaining their ISO 9001:2015 Certification. This is an
                extremely rigorous process to go through and should not be taken
                likely. Adrian is a true testament to hard work and
                determination. He has continued to ensure that if there is an
                opportunity out there for him to bid on he has the necessary
                credentials to qualify and succeed. Kudos to RedLaser!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='u-center-text u-margin-top-huge'>
        <Link to='/testimonials' className='btn-text'>
          Read more testimonials &rarr;
        </Link>
      </div>
    </section>
  );
};

export default Testimonials;
