import InnerHeader from '../../components/innerheader/InnerHeader';
import './pastperformancesview.scss';

const PastPerformancesView = () => {
  let casestudies = [
    {
      id: 1,
      clientName: 'Mississippi Development Authority',
      cost: '$150,000',
      summary:
        'The Mississippi Development Authority (MDA) sought to update the design and structure of its site. They wanted overall improvement on the organization and presentation of the content so visitors can quickly navigate to desired pages and eliminate the current confusion regarding MSCSPC employees, MDA affiliates, and Mississippi small business.',
      approach:
        'Red Laser Technology (RLT) approached the client engagement using our documented set of policies, processes, and procedures including discovery, planning, design and production. We then defined features concretely, determined technology integration specifics, and prioritized features so we could provide the greatest return on investment for MDA.',
    },
    {
      id: 2,
      clientName: 'Gulfport-Biloxi International Airport',
      cost: '$1.8 Million ',
      summary:
        'The Gulfport-Biloxi International Airport needed to expand their wireless network to remote locations. RLT upgraded the airports aging network to more modern network design, network services, IT network management services, IT systems development services, IT systems analysis services, programming, project management, and data/records management.',
      approach:
        'RLT’s vigilance in monitoring the Gulfport-Biloxi International Airport systems with the use of our Network Performance Monitoring (NPM) platform minimized adverse effects of any incident by following a problem management process protocol including \n - Detection and Recording \n - Grouping and Initial Support \n - Investigation and Diagnosis \n - Resolution and Recovery \n - Closure',
    },
    {
      id: 3,
      clientName: 'Jackson State University ',
      cost: '$149,000',
      summary:
        'Performed internal and external network vulnerability scanning; assessment of Jackson State University wireless networks for security issues and document all findings.',
      approach:
        'Red Laser Technology (RLT) focused on the continuous assessment of the Jackson State’s IT controls. As we begun the penetration testing, RLT worked closely with management to better understand key technology issues and changes in the organization’s IT environment. These services strengthen and harden any vulnerability in the Jackson State’s network and web applications preventing unwanted downtime',
    },
  ];

  return (
    <div>
      <InnerHeader>Past Performances</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='pastperformances'>
            {casestudies.map((item, index) => {
              return (
                <div className='casestudy' key={index}>
                  <div className='casestudy-heading'>Client</div>
                  <div className='casestudy-detail clientidentity'>
                    {item.clientName}
                  </div>
                  {/* <div className='casestudy-heading'>Cost</div> */}
                  {/* <div className='casestudy-detail'>{item.cost}</div> */}
                  <div className='casestudy-heading'>Summary</div>
                  <div className='casestudy-detail'>{item.summary}</div>
                  <div className='casestudy-heading'>Approach</div>
                  <div className='casestudy-detail'>{item.approach}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastPerformancesView;
