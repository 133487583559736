import InnerHeader from '../../components/innerheader/InnerHeader';
import { FaChevronRight } from 'react-icons/fa';
import './generalconstructionview.scss';

const GeneralConstructionView = () => {
  return (
    <div>
      <InnerHeader>General Construction</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='cloudcomputing'>
            <div className='cloudcomputing-text'>
              General contractors lead the construction projects. They are
              specialized professionals who are essential for different parts of
              the process and for executing the project successfully. In order
              to save time and promote productivity, general contractors mostly
              hire subcontractors. Their main responsibility is to make sure
              that the projects are delivered as per the client’s guidelines and
              also in compliance with the law. Design, remodel or build, their
              construction projects may include any job that needs a building
              permit. Be it the construction of a house or putting on a room,
              the skills of general contractors are always in demand at Red
              Laser Technology.
            </div>
            <div className='cloudcomputing-benefits'>
              <h3 className='inner-para-heading'>Benefits</h3>
              <h4 className='cloudcomputing-benefits-heading'>
                1. Affordability
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Negotiate well in acquiring different materials from suppliers
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Offers high-quality service, and ensure more affordability.
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Enable the subcontractors to charge less which leads to the
                smooth execution of the construction project.
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Result in increased business revenue.
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                2. Time Efficient
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Have the ability to continue the work even during unforeseen
                circumstances
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Prepare the subcontractors in working smoothly, handle issues
                without hampering the project progress, avoid any delays.
              </div>

              <h4 className='cloudcomputing-benefits-heading'>
                3. Licensing and Insurance
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Prevent you from being liable for any damages or accidents that
                happen on your construction site
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Cover the project with insurance and make sure that all the
                rules and regulations by the state board are followed.
              </div>
            </div>
            <div className='cloudcomputing-text'>
              Red Laser Technology lives up to its motto,
              <span>"Empowerment through Innovation”</span>. The Vicksburg
              District Antiterrorism Program was selected as the "Best
              Standalone Facility Antiterrorism Program" by the Corps of
              Engineers. The company’s staff, with its depth of experience,
              invested a lot of effort into the District's AT program and was
              recognized with an award.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralConstructionView;
