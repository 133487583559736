import InnerHeader from '../../components/innerheader/InnerHeader';
import { FaChevronRight } from 'react-icons/fa';
import './softwaredevelopmentview.scss';

const SoftwareDevelopmentView = () => {
  return (
    <div>
      <InnerHeader>Software Development</InnerHeader>
      <div className='inner-content'>
        <div className='maingrid'>
          <div className='cloudcomputing'>
            <div className='cloudcomputing-text'>
              Software & Application development enables you to create computer
              software with relevant programming languages that help in
              executing business tasks. Simply put, it is a process of
              developing programs to perform a plethora of activities. Gaming
              applications and Media streaming development are examples of
              Software & Application Development. Whenever you plan to create
              computer software, you need to follow a few steps that are common
              to all, namely identifying requirements, designing prototypes,
              testing, implementation, and maintenance.
              <div className='cloudcomputing-text'>
                It is to be noted that every app-building procedure begins with
                planning the type of application to be developed. From
                optimizing business processes to increasing reliability, Red
                Laser Technology ensures helping you achieve your enterprise
                objectives.
              </div>
            </div>
            <div className='cloudcomputing-benefits'>
              <h3 className='inner-para-heading'>Benefits</h3>
              <h4 className='cloudcomputing-benefits-heading'>
                1. Lower IT costs
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Reduce the training expenses
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Minimize the resource requirements
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Cut down the license fees
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Give a boost to the overall business productivity.
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                2. Significant Business Recognition
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Ensure better performance of your enterprise
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Improve customer satisfaction
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Leads to increased revenue and ROI
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Makes use of RLT’s exceptional service for the creation of your
                brand value.
              </div>
              <h4 className='cloudcomputing-benefits-heading'>
                3. Competitive edge
              </h4>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Effective customer engagement, flexibility, and marketing
              </div>
              <div className='cloudcomputing-benefits-content'>
                <span>
                  <FaChevronRight />
                </span>
                Allow you to keep opting for dynamic strategies to survive in
                the market and compete with your rivals.
              </div>
            </div>
            <div className='cloudcomputing-text'>
              Red Laser Technology stays true to its philosophy,
              <span>"Empowerment through Innovation”</span>. The Vicksburg
              District Antiterrorism Program was selected as the "Best
              Standalone Facility Antiterrorism Program" by the Corps of
              Engineers. RLT's Staff put a lot of effort into the District's AT
              program and was awarded for it!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDevelopmentView;
