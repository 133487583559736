const logoimages = [
  {
    id: 1,
    src: 'img/logos/logo1.png',
    alt: 'Image 1',
  },
  {
    id: 2,
    src: 'img/logos/logo2.png',
    alt: 'Image 2 ',
  },
  {
    id: 3,
    src: 'img/logos/logo3.png',
    alt: 'Image 3',
  },
  {
    id: 4,
    src: 'img/logos/logo4.png',
    alt: 'Image 4 ',
  },
  {
    id: 5,
    src: 'img/logos/logo5.png',
    alt: 'Image 5',
  },
  {
    id: 6,
    src: 'img/logos/logo6.png',
    alt: 'Image 6 ',
  },
  {
    id: 7,
    src: 'img/logos/logo7.png',
    alt: 'Image 7',
  },
  {
    id: 8,
    src: 'img/logos/logo8.png',
    alt: 'Image 8',
  },
  {
    id: 9,
    src: 'img/logos/logo9.png',
    alt: 'Image 9',
  },
  {
    id: 10,
    src: 'img/logos/logo10.png',
    alt: 'Image 10',
  },
  {
    id: 11,
    src: 'img/logos/logo11.png',
    alt: 'Image 11',
  },
  {
    id: 12,
    src: 'img/logos/logo12.png',
    alt: 'Image 12',
  },
  {
    id: 13,
    src: 'img/logos/logo13.png',
    alt: 'Image 13',
  },
  {
    id: 14,
    src: 'img/logos/logo14.png',
    alt: 'Image 14',
  },
  {
    id: 15,
    src: 'img/logos/logo15.png',
    alt: 'Image 15',
  },
];
export default logoimages;
