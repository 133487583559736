import HeaderHome from './headerhome/HeaderHome';
import HomeLogoSlider from './homelogoslider/HomeLogoSlider';
import SectionAbout from './sectionabout/SectionAbout';

import logoimages from './homelogoslider/logoimages';
import Testimonials from './testimonials/Testimonials';
import PastPerformances from './pastperformances/PastPerformances';

const HomeView = () => {
  return (
    <div>
      <HeaderHome />
      <SectionAbout />
      <HomeLogoSlider logoimages={logoimages} />
      <Testimonials />
      <PastPerformances />
    </div>
  );
};

export default HomeView;
